import React from "react";
// import PropTypes from 'prop-types'
// import { Link, graphql } from 'gatsby'

export default class IndexPage extends React.Component {
  render() {
    // const { data } = this.props

    return (
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="has-text-weight-bold is-size-2">Thank you</h1>
            <p>Grazie per aver commentato</p>
          </div>
        </div>
      </section>
    );
  }
}

IndexPage.propTypes = {};
